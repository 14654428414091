<template>
  <div>
    <section class="main my-md patterned-background">
      <div class="d-flex flex-column-reverse flex-md-row mb-5">
        <div class="col-12 col-md-6 d-flex flex-column p-3">
          <h1 class="f-secondary t-secondary p-2 text-gradient-2">
            Coming Soon...
          </h1>
          <div class="f-primary p-2 line-height-30 mb-3">
            Unlock Your Potential… We’re partnering with the world’s leading
            training providers, schools, and universities to bring you a
            comprehensive range of health and social care courses designed to
            enhance your skills and advance your career. Each course provider is
            accredited and recognized within their specialism, ensuring you
            receive top-quality education and training. Our offerings include
            English language training and online learning from the UK NHS’s
            trusted online course provider. Whether you’re looking to upskill,
            specialize, or embark on a new career path, our courses will offer
            the knowledge and practical experience needed to excel in real-world
            healthcare settings. TalentFind is committed to providing you easy
            access to recognized courses, so stay with us as you’ll find more
            exciting partnerships are on the way! Stay tuned for more details
            and get ready to take the next step in your professional journey!
          </div>
        </div>
        <div class="col-12 col-md-6">
          <img
            src="../../../public/img/stm/professionals.png"
            class="img-fluid position-relative z-1"
          />
        </div>
      </div>
    </section>
    <NewFooter />
    <ScrollToTop />
  </div>
</template>

<script>
import ScrollToTop from "../../components/reusable/ScrollToTop.vue";
import NewFooter from "./NewFooter.vue";
import { mapActions } from "vuex";

export default {
  components: {
    NewFooter,
    ScrollToTop,
  },
  methods: {
    ...mapActions(["scrollTop"]),
  },
  mounted() {
    this.scrollTop();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
