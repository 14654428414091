import { render, staticRenderFns } from "./ComingSoonSection.vue?vue&type=template&id=36b0fea0&scoped=true"
import script from "./ComingSoonSection.vue?vue&type=script&lang=js"
export * from "./ComingSoonSection.vue?vue&type=script&lang=js"
import style0 from "./ComingSoonSection.vue?vue&type=style&index=0&id=36b0fea0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b0fea0",
  null
  
)

export default component.exports